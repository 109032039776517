body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Cooper';
  src: local('Cooper'), url(fonts/cooper.ttf) format('truetype');
}

button.Polaris-Button--primary:not(.Polaris-Button--destructive),
a.Polaris-Button--primary:not(.Polaris-Button--destructive) {
  background-color: #72a295;
}

button.Polaris-Button--primary:hover:not(.Polaris-Button--destructive),
a.Polaris-Button--primary:hover:not(.Polaris-Button--destructive) {
  background-color: #649789;
}

.Polaris-DataTable__Cell {
  white-space: inherit !important;
}

.respondents-table-wrapper .rdt_TableCol {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.respondents-table-wrapper .MuiBox-root {
  width: 100%;
}

.survey-mapping-table-wrapper .rdt_TableCol:last-child {
  flex-direction: row-reverse;
}

@media (min-width: 961px) {
  .Polaris-TopBar__NavigationIcon {
    display: none !important;
  }
}

@media (max-width: 960px) {
  .Polaris-TopBar__NavigationIcon {
    display: inline !important;
  }
}

@media (max-width: 960px) {
  html,
  body {
    overflow-x: hidden;
  }
}

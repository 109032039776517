.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dx-filterbuilder {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.dx-button {
  margin: 10px;
  float: right;
}

.dx-filterbuilder .dx-numberbox {
  width: 80px;
}

.insighterNote:hover {
  cursor: pointer;
}

#Insighter-Autocomplete-Input {
  font-size: 1.4rem;
}

.Polaris-Indicator.warning::before {
  background-color: red;
}

.rdw-editor-main {
  border: solid darkgray 1px;
  border-radius: 4px;
  min-height: 150px;
  background-color: gainsboro;
}

.osano-cm-window {
  z-index: 1 !important;
}

.Polaris-Frame__Navigation--enterActive {
  z-index: 10000 !important;
}

.uploaded-content h1 {
  font-family: 'Crimson Text';
  line-height: 1.1;
  font-size: 3.5rem;
}

.uploaded-content h2 {
  font-family: 'Crimson Text';
  font-size: 3rem;
}

.uploaded-content h3 {
  font-family: 'Crimson Text';
  font-size: 2.5rem;
}

.uploaded-content p {
  font-family: 'Rethink Sans', sans-serif;
  line-height: 1.2;
  font-size: 1.6rem;
}

.uploaded-content p a {
  color: lightblue;
}

.uploaded-content p a:hover {
  color: yellow;
}

button.primary-btn {
  background-color: #72a295;
  color: white;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 8;
  text-transform: none;
  font-weight: var(--p-button-font-weight, 400);
  padding-left: 8px;
  padding-right: 8px;
}

button.primary-btn:hover {
  color: #333;
}

button.secondary-btn {
  background-color: #fff;
  color: #444;
  height: 40px;
  width: auto;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 8;
  text-transform: capitalize;
  font-weight: var(--p-button-font-weight, 400);
  border: 1px solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  padding-left: 8px;
  padding-right: 8px;
}

button.destructive-btn {
  background-color: indianred;
  color: white;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 8;
  text-transform: none;
  font-weight: 700;
}

button.auth-btn {
  background-color: #e7ac34;
  color: black;
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 8;
  text-transform: capitalize;
  font-weight: 400;
  font-family: 'Cooper';
}

p.wrap-field {
  display: flex;
  justify-content: flex-start;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
